@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

html,
body,
#root,
.App {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.particle {
    position: absolute;
    transition: all 0.5s ease;
    user-select: none;
}
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

button {
    width: 120px;
    height: 50px;
    border-radius: 15px;
    border: none;
    background-color: #4caf50;
}

.z1 {
    z-index: 1;
}

::selection {
    background: #4caf50;
    color: #fff;
}

* {
    font-family: "Poppins", sans-serif;
}

.cursor {
    position: absolute;
    border-radius: 100px;
    border: 6px solid black;
    z-index: 2;
    transition: border 0.5s ease;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
}
